// File upload with preview

.custom-file-container {
  box-sizing: border-box;
  position: relative;
  display: block;
  &__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px;
    &:hover {
      cursor: pointer;
    }
    &__custom-file-input {
      box-sizing: border-box;
      min-width: 14rem;
      max-width: 100%;
      height: calc(2.25rem + 2px);
      margin: 0;
      opacity: 0;
      // on input focus, applies focus style to the styled input span
      &:focus ~ span {
        outline: 1px dotted $primary;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }
    &__custom-file-control {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 5;
      height: calc(2.4rem + 2px);
      padding: 0.5rem 1.2rem;
      overflow: hidden;
      line-height: 1.5;
      color: $gray-900;
      user-select: none;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid $gray-300;
      border-radius: 0.25rem;
      &__button {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 6;
        display: block;
        height: calc(2.4rem + 2px);
        padding: 0.5rem 1.2rem;
        line-height: 1.5;
        color: $gray-900;
        background-color: $white;
        border-left: 1px solid $gray-300;
        box-sizing: border-box;
      }
    }
  }
  &__image-preview {
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $white;
    overflow: auto;
  }
  &__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736059%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba($gray-900, 0.25);
    &__single-image-clear {
      left: -6px;
      background: $gray-100;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      margin-top: -6px;
      box-shadow: 0 4px 10px 0 rgba($gray-900, 0.25);
      &:hover {
        background: darken($gray-100, 15%);
        cursor: pointer;
      }
      &__icon {
        color: darken($gray-100, 55%);
        display: block;
        margin-top: -2px;
      }
    }
  }
}
