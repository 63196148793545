#category-slider {
  max-width: 1080px;
  padding: 0 120px;
  margin: 0 auto;

  img {
    width: 100%;
    object-fit: cover;
  }

  .slick-slider {
    width: 100%;
  }

  .item {
    position: relative;
    width: 100%;
    height: 468px;

    >.add {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-no-slider {
      max-width: 280px;
    }
  }

  .add-slide {}

  @media (max-width: 1400px) {
    padding: 0 100px;
  }

  @media (max-width: 1200px) {
    padding: 0 80px;
  }


  @media (max-width: 992px) {
    padding: 0 40px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}